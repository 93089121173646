import { Link } from 'react-router-dom';
import {
  ELinkHrefOption,
  IComponentsContext,
  TLinkComponent,
} from 'petdna-lib-uicomponents/types';

export const defaultComponents: IComponentsContext = {
  Link: Link as React.ComponentType<TLinkComponent>,
  LinkProps: {
    href: ELinkHrefOption.to,
  },
};
